<template>
  <div class="category-selection rubik-font">
    <header>
      <div class="header-left">
        <div class="back-arrow"><a href="https://chlastaci-hry.cz"><img src="@/assets/Graphics/back-arrow.png" alt="Back"></a></div>
      </div>
      <div class="header-center">
        <img src="@/assets/Graphics/logo.png" alt="Logo" class="app-logo">
      </div>
      <div class="header-right">
        <div v-if="isLoggedIn" class="user-phone">
          {{ userPhoneNumber }}
          <button @click="logoutUser" class="logout-button">Odhlásit se</button>
        </div>

        <img v-else src="@/assets/Graphics/user-icon.png" alt="User" class="user-icon" @click="toggleLoginForm">
        <img src="@/assets/Graphics/settings-icon.png" alt="Settings" class="settings-icon" @click="toggleSettings">
      </div>
    </header>

    <div v-if="showLoginForm" class="login-form">
      <h3>Přihlášení</h3>
      <form @submit.prevent="handleLogin">
        <div class="form-group phone-input">
          <input type="text" id="countryCode" v-model="countryCode" required>
          <input type="tel" id="phone" placeholder="731 838 191" v-model="phoneNumber" required>
        </div>
        <div v-if="showVerificationField" class="form-group">
          <label for="code">Ověřovací kód:</label>
          <input type="text" id="code" v-model="verificationCode" required>
        </div>
        <button type="submit">{{ showVerificationField ? 'Ověřit kód' : 'Odeslat kód' }}</button>
      </form>
    </div>

    <main>
      <div class="categories-container">
        <section class="categories">
          <h2>Kategorie:</h2>
          <div class="category-grid">
            <button v-for="category in regularCategories" :key="category.age" @click="startGame(category.age + '+')" class="category-btn">
              {{ category.emoji }}{{ category.age }}+{{ category.emoji }}
            </button>
          </div>
        </section>

        <section class="premium-categories">
          <h2>Prémiové kategorie:</h2>
          <div class="premium-category-grid">
            <button v-for="category in premiumCategories" :key="category.name" @click="startGame(category.name)" class="premium-category-btn" :disabled="false"
      >
        {{ category.emoji }}{{ category.name }}{{ category.emoji }}
        <span v-if="!unlockedCategories.includes(category.name)" class="lock-icon">
          <img src="@/assets/Graphics/lock-icon.png" alt="Lock">
        </span>
      </button>
          </div>
        </section>
      </div>
    </main>

    <footer>
      <div class="social-icons">
        <a href="https://x.com/chlastaci_hry" target="_blank">
          <img src="@/assets/Graphics/twitter-icon.png" alt="Twitter">
        </a>
        <a href="https://www.instagram.com/chlastaci.hry/" target="_blank">
          <img src="@/assets/Graphics/instagram-icon.png" alt="Instagram">
        </a>
        <a href="https://www.tiktok.com/@chlastaci.hry" target="_blank">
          <img src="@/assets/Graphics/tiktok-icon.png" alt="TikTok">
        </a>
      </div>
      <div class="copyright">© Created by Dominik.</div>
    </footer>

    <SettingsModal 
      :show="showSettings" 
      @close="toggleSettings"
      @showLogin="toggleLoginForm"
    />
    </div>
    <div id="recaptcha-container"></div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import SettingsModal from './SettingsModal.vue'

export default {
  name: 'CategorySelection',
  components: {
    SettingsModal
  },
  data() {
    return {
      showLoginForm: false,
      showSettings: false,
      countryCode: '+420',
      phoneNumber: '',
      verificationCode: '',
      showVerificationField: false,
      regularCategories: [
        { age: 12, emoji: ' 😃 ' },
        { age: 15, emoji: ' 🔥 ' },
        { age: 18, emoji: ' 🍸 ' },
        { age: 21, emoji: ' 😈 ' }
      ],
      premiumCategories: [
        { name: 'Páry', emoji: ' 💕 ' },
        { name: 'Sexuální', emoji: ' 🔞 ' },
        { name: 'Kontroverzní', emoji: ' 🤭 ' },
        { name: 'Mix', emoji: ' 💥 ' }
      ]
    }
  },
  computed: {
    ...mapGetters(['isLoggedIn', 'isPremium', 'unlockedCategories', 'currentUser', 'subscriptionMessage', 'subscriptionHistory']),
    userPhoneNumber() {
      if (this.currentUser && this.currentUser.phoneNumber) {
        return this.currentUser.phoneNumber.replace('+420', '')
      }
      return ''
    }
  },

  created() {
    console.log('CategorySelection created');
    console.log('isLoggedIn:', this.isLoggedIn);
    console.log('isPremium:', this.isPremium);
    console.log('unlockedCategories:', this.unlockedCategories);
    console.log('currentUser:', this.currentUser);
    console.log('subscriptionMessage:', this.subscriptionMessage);
  },

  mounted() {
    console.log('CategorySelection mounted');
    console.log('isLoggedIn:', this.isLoggedIn);
    console.log('isPremium:', this.isPremium);
    console.log('unlockedCategories:', this.unlockedCategories);
    console.log('currentUser:', this.currentUser);
    console.log('subscriptionMessage:', this.subscriptionMessage);
  },

  watch: {
    isLoggedIn(newValue, oldValue) {
      console.log('isLoggedIn changed:', oldValue, '->', newValue);
    },
    isPremium(newValue, oldValue) {
      console.log('isPremium changed:', oldValue, '->', newValue);
    },
    unlockedCategories: {
      handler(newValue, oldValue) {
        console.log('unlockedCategories changed:', oldValue, '->', newValue);
      },
      deep: true
    },
    currentUser: {
      handler(newValue, oldValue) {
        console.log('currentUser changed:', oldValue, '->', newValue);
      },
      deep: true
    },
    subscriptionMessage(newValue, oldValue) {
      console.log('subscriptionMessage changed:', oldValue, '->', newValue);
    }
  },


  methods: {
    ...mapActions(['initializePhoneAuth', 'requestPhoneAuth', 'verifyPhoneCode', 'loadQuestions', 'checkSubscriptionStatus', 'logout', 'checkSubscriptionIfNeeded']),
    
    toggleLoginForm() {
      console.log('toggleLoginForm called');
      this.showLoginForm = !this.showLoginForm
      if (!this.showLoginForm) {
        this.showVerificationField = false
        this.phoneNumber = ''
        this.verificationCode = ''
      }
    },

    toggleSettings() {
      console.log('toggleSettings called');
      this.showSettings = !this.showSettings
    },

    formatDate(timestamp) {
      return new Date(timestamp * 1000).toLocaleDateString()
    },

    async handleLogin() {
      console.log('handleLogin called');
      if (!this.showVerificationField) {
        try {
          const fullPhoneNumber = this.countryCode + this.phoneNumber;
          const success = await this.requestPhoneAuth(fullPhoneNumber);
          if (success) {
            this.showVerificationField = true;
          }
        } catch (error) {
          console.error('Chyba při odesílání kódu:', error);
          alert('Chyba při odesílání kódu: ' + error.message);
        }
      } else {
        try {
          await this.verifyPhoneCode({ 
            code: this.verificationCode, 
            fullPhoneNumber: this.countryCode + this.phoneNumber 
          });
          console.log('Úspěšně přihlášeno');
          this.showLoginForm = false;
          this.showVerificationField = false;
          const fullPhoneNumber = this.countryCode + this.phoneNumber;
          await this.checkSubscriptionStatus(fullPhoneNumber);
          if (this.subscriptionMessage) {
            alert(this.subscriptionMessage);
          }
        } catch (error) {
          console.error('Chyba při ověřování kódu:', error);
          alert('Chyba při ověřování kódu: ' + error.message);
        }
      }
    },
    async startGame(category) {
      console.log('startGame called with category:', category);
      console.log('Current isPremium:', this.isPremium);
      console.log('Current unlockedCategories:', this.unlockedCategories);
      await this.checkSubscriptionIfNeeded();
      
      if (this.premiumCategories.some(cat => cat.name === category) && !this.unlockedCategories.includes(category)) {
        if (confirm(`Kategorie "${category}" je dostupná pouze pro prémiové uživatele. Chcete si zakoupit předplatné?`)) {
          window.location.href = 'https://chlastaci-hry.cz/predplatne';
        }
        return;
      }
      
      try {
        const categoryArray = [category.replace('+', '')]
        await this.loadQuestions(categoryArray)
        this.$router.push({ name: 'Game', params: { category: categoryArray[0] } })
      } catch (error) {
        console.error('Chyba při načítání otázek:', error)
        alert('Chyba při načítání otázek: ' + error.message)
      }
    },
    async logoutUser() {
      console.log('logoutUser called');
      try {
        await this.logout()  // Toto volá Vuex akci
        console.log('Úspěšně odhlášeno')
        window.location.reload()
      } catch (error) {
        console.error('Chyba při odhlašování:', error)
        alert('Chyba při odhlašování: ' + error.message)
      }
    },
    async mounted() {
      await this.initializePhoneAuth();
      if (this.isLoggedIn && this.currentUser && this.currentUser.phoneNumber) {
        await this.checkSubscriptionStatus(this.currentUser.phoneNumber);
        if (this.subscriptionMessage) {
          alert(this.subscriptionMessage);
        }
      }
    },
    watch: {
      async currentUser(newUser) {
        if (newUser && newUser.phoneNumber) {
          await this.checkSubscriptionStatus(newUser.phoneNumber);
        }
      }
    }
}
}
</script>


<style>
@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');

.rubik-font {
  font-family: "Rubik", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}
</style>

<style scoped>
.category-selection {
  min-height: 100vh;
  background: linear-gradient(to right, #ff69b4, #ff8c00);
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 5%;
  padding-right: 5%;
  font-family: "Rubik", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  width: 100%;
  padding-top: 40px;
  padding-bottom: 40px;
}

.header-left, .header-right {
  flex: 1;
  display: flex;
  align-items: center;
}

.header-center {
  flex: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-right {
  justify-content: flex-end;
}

.app-logo {
  height: 60px;
}

.back-arrow img {
  height: 40px;
  cursor: pointer;
  transition: transform 0.3s;
}

.back-arrow img:hover {
  transform: scale(1.2);
}

.user-icon, .settings-icon {
  height: 40px;
  cursor: pointer;
  margin-left: 20px;
  transition: transform 0.3s;
}

.user-icon:hover, .settings-icon:hover {
  transform: scale(1.2);
}

.user-phone {
  font-size: 1.2rem;
  margin-right: 20px;
}

.logout-button {
  background: none;
  color: white;
  border: none;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  margin-left: 0;
}

main {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.categories-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 400px;
  max-width: 1100px;
  margin: 0 auto;
}

.categories, .premium-categories {
  width: 48%;
  display: flex;
  flex-direction: column;
}

h2 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  text-align: center;
}

.category-grid, .premium-category-grid {
  display: grid;
  gap: 1rem;
  width: 100%;
  height: 100%;
}

.category-grid {
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
}

.premium-category-grid {
  grid-template-columns: 1fr;
  grid-template-rows: repeat(4, 1fr);
}

.category-btn, .premium-category-btn {
  background-color: white;
  color: black;
  border: none;
  border-radius: 20px;
  font-size: 1.5rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.3s;
  width: 100%;
  height: 100%;
  padding: 0.5rem;
}

.category-btn:hover, .premium-category-btn:hover {
  transform: scale(1.02);
}

.premium-category-btn {
  position: relative;
}

.lock-icon img {
  position: absolute;
  right: 1rem;
  height: 35px;
  top: 50%;
  transform: translateY(-50%);
}

footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  width: 100%;
  padding-top: 50px;
}

.social-icons a img {
  padding-right: 10px;
  height: 30px;
}

.social-icons img:last-child {
  margin-right: 0;
}

.copyright {
  font-size: 1rem;
}

.login-form {
  position: absolute;
  top: 110px;
  right: 90px;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
  z-index: 1000;
  width: 280px;
  box-sizing: border-box;
}

.login-form * {
  box-sizing: border-box;
}

.login-form h3 {
  margin-top: 0;
  color: #333;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  color: #666;
}

.form-group input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-right: 0;
}

.login-form button {
  width: 100%;
  padding: 10px;
  background-color: #ff69b4;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.login-form button:hover {
  background-color: #ff8c00;
}

.phone-input {
  display: flex;
  gap: 10px;
}
.phone-input input[type="text"] {
  width: 70px;
}
.phone-input input[type="tel"] {
  flex-grow: 1;
}

.grecaptcha-badge { 
    visibility: hidden;
    display:none;
}


@media (max-width: 800px) {
  .login-form {
    right: 20px;
    width: calc(100% - 40px);
    max-width: 280px;
  }

  .categories-container {
    flex-direction: column;
    height: auto;
    max-width: 500px;
  }

  .categories, .premium-categories {
    width: 100%;
    margin-bottom: 2rem;
  }

  .premium-categories {
    margin-bottom: 0;
  }

  .category-grid, .premium-category-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
  }

  .category-grid {
    height: 170px;
  }

  .premium-category-grid {
    height: 330px;
  }

  .category-btn, .premium-category-btn {
    flex: 1 0 calc(50% - 0.25rem);
    height: 80px;
    font-size: 1rem;
    padding: 0.5rem;
  }

  .premium-category-btn {
    flex-basis: 100%;
  }
}

/* Mobile responzive */
@media (max-width: 400px) {
header {
  padding-top: 20px;
}
.back-arrow img, .user-icon, .settings-icon {
  height: 30px;
}
.app-logo {
  height: 50px;
}
.social-icons a img {
  padding-right: 7px;
  height: 25px;
}
}
</style>
